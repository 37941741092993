import {
  Box,
  Center,
  Stack,
  HStack,
  Text,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { OrderItemDataFragment, Enum_Orderitem_Status } from "data";
import { ActionButton } from "components/utils/ActionButton";
import { PrintIcon, DeleteIcon } from "components/Icons";
import { useLabelPrinter } from "context/LabelPrinter";
import { ShowForHashboard } from "features/devices/components/ShowForHashboard";
import { CustomerDeviceLabel } from "features/customer-devices/components/CustomerDeviceLabel";
import { DeviceCompleteNameLabel } from "features/devices/components/DeviceCompleteNameLabel";
import { ShowForOrderItemStatus } from "features/order-items/components/ShowForOrderItemStatus";
import { OrderItemHostedStatusBadgeContainer as OrderItemHostedStatusBadge } from "features/order-items/containers/OrderItemHostedStatusBadge";
import { DiagnosticsAction } from "features/diagnostics/components/DiagnosticsAction";
import { RepairAction } from "features/repairs/components/RepairAction";
import { QAAction } from "features/qa/components/QAAction";
import { ScrapForPartsAction } from "features/order-items/components/ScrapForPartsAction";
import { RecycleAction } from "features/order-items/components/RecycleAction";
import { ReturnToCustomerAction } from "features/order-items/components/ReturnToCustomerAction";
import { ApplyThermalPasteAction } from "./actions/ApplyThermalPaste";
import { ReflowAction } from "./actions/Reflow";
import { CleanAction } from "./actions/Clean";
import { ShowForPSU } from "features/devices/components/ShowForPSU";
import { ReplacePSUFanAction } from "./actions/ReplacePSUFan";

interface OrderItemContextPanelProps {
  orderItem: OrderItemDataFragment;
  deleteOrderItem: () => Promise<void>;
}

export const OrderItemContextPanel = (props: OrderItemContextPanelProps) => {
  const { printableRef, print } = useLabelPrinter();
  const { deleteOrderItem, orderItem } = props;
  const customerDevice = orderItem.attributes?.customer_device?.data;
  const device = orderItem.attributes?.device?.data;
  return (
    <Tabs height='full'>
      <TabList>
        <Tab>Overview</Tab>
      </TabList>

      <TabPanels height='full'>
        <TabPanel>
          <Box pt='20'>
            {customerDevice ? (
              <>
                {/* Variation for paired devices */}
                <CustomerDeviceLabel
                  w='32'
                  h='32'
                  device={customerDevice}
                  printableRef={printableRef}
                />
                <Center>
                  <Stack spacing='4' mt={20} w='100%'>
                    <OrderItemHostedStatusBadge orderItem={orderItem} editable />
                    <ActionButton
                      action={async () => {
                        print();
                      }}
                    >
                      <HStack>
                        <PrintIcon />
                        <Text>Print</Text>
                      </HStack>
                    </ActionButton>
                    <DiagnosticsAction orderItem={orderItem} variant='button' />

                    <ShowForHashboard device={device}>
                      <>
                        <ShowForOrderItemStatus
                          orderItem={orderItem}
                          status={[
                            Enum_Orderitem_Status.DiagnosedNeedsRepair,
                            Enum_Orderitem_Status.RepairedFailed,
                          ]}
                        >
                          <RepairAction orderItem={orderItem} variant='button' />
                        </ShowForOrderItemStatus>
                        <ApplyThermalPasteAction orderItem={orderItem} variant='button' />
                        <ReflowAction orderItem={orderItem} variant='button' />
                        <CleanAction orderItem={orderItem} variant='button' />
                      </>
                    </ShowForHashboard>

                    <ShowForPSU device={device}>
                      <ReplacePSUFanAction orderItem={orderItem} variant='button' />
                    </ShowForPSU>

                    <ShowForOrderItemStatus
                      orderItem={orderItem}
                      status={[
                        Enum_Orderitem_Status.RepairedNeedsQa,
                        Enum_Orderitem_Status.DiagnosedIsOk,
                      ]}
                    >
                      <QAAction orderItem={orderItem} />
                    </ShowForOrderItemStatus>
                    <ShowForOrderItemStatus
                      inverse
                      orderItem={orderItem}
                      status={[
                        Enum_Orderitem_Status.QaFailRecycled,
                        Enum_Orderitem_Status.QaFailReturnToCustomer,
                      ]}
                    >
                      <>
                        <ReturnToCustomerAction orderItem={orderItem} />
                        <RecycleAction orderItem={orderItem} />
                        <ScrapForPartsAction orderItem={orderItem} />
                      </>
                    </ShowForOrderItemStatus>
                  </Stack>
                </Center>
              </>
            ) : (
              <>
                {/* Variation for unpaired devices */}
                <Center>
                  <Stack>
                    <DeviceCompleteNameLabel
                      showMakeIcon
                      fontWeight='bold'
                      fontSize='lg'
                      device={device}
                    />

                    <ActionButton
                      size='sm'
                      colorScheme='red'
                      aria-label='Delete order item'
                      action={async () => {
                        await deleteOrderItem();
                      }}
                      confirmationMessage='Are you sure you want to delete this item?'
                    >
                      <HStack data-cy='action-button-delete-order-item'>
                        <DeleteIcon />
                        <Text>Delete</Text>
                      </HStack>
                    </ActionButton>
                  </Stack>
                </Center>
              </>
            )}
          </Box>
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};
